/* eslint-disable no-process-env */
import { getPurposityEnv } from 'purposity-env'

export const SENTRY_DSN =
  'https://ea881d09167e42e5a1d517c9b5bc9faa@o546790.ingest.sentry.io/4504715393761280'

export const PURPOSITY_ENV = getPurposityEnv({
  VERCEL_ENV: process.env.VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NODE_ENV: process.env.NODE_ENV,
  DEBUG: process.env.DEBUG,
})
